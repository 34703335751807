<template>
  <div
    class="rounded w-full min-h-80px cursor-pointer flex flex-row pl-5 pt-6"
    v-bind="$attrs"
    :style="style"
    @click="$emit('selectBranch', branch)"
  >
    <div
      class="rounded-full h-25px w-25px bg-blue-500 flex justify-center items-center"
    >
      <ion-icon name="location-outline" class="text-base text-white"></ion-icon>
    </div>
    <div class="ml-5 flex flex-col w-9/12 mb-4 text-deal">
      <h4 class="text-14 font-semibold">
        {{ branch.name | sentenceCase }}
      </h4>
      <p class="text-12 font-thin tracking-tight mt-1">
        {{ `${branch.address}` }}
      </p>
    </div>
    <div>
      <ion-icon
        name="chevron-forward-outline"
        class="mt-2 ml-2 text-lg text-blue-500"
      ></ion-icon>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    branch: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      return {
        background: "rgba(36, 45, 79, 0.02)",
        border: "0.5px solid rgba(36, 45, 79, 0.08)"
      };
    }
  }
};
</script>
